var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "view-contanier" }, [
    _c(
      "div",
      { staticClass: "opt" },
      [
        _c("el-input", {
          staticStyle: { width: "500px" },
          attrs: { placeholder: "请输入订单编号，客户单号" },
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v
            },
            expression: "search",
          },
        }),
        _c(
          "el-button",
          {
            staticStyle: { "margin-left": "10px", height: "40px" },
            attrs: { type: "primary", icon: "el-icon-search" },
            on: { click: _vm.searchRefNo },
          },
          [_vm._v("查询")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "data" },
      [
        _vm.height !== 0
          ? _c("BaseTable", {
              attrs: {
                height: _vm.height,
                isPager: false,
                tableData: _vm.tableData,
                loading: _vm.loading,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "companyName",
                    fn: function (row) {
                      return [
                        row.tIndex === 0 && !row.scope.errorMessage
                          ? _c("div", { staticClass: "optimal flex_c_c" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/order/praise.png"),
                                },
                              }),
                              _vm._v("价格最优 "),
                            ])
                          : _vm._e(),
                        _c("div", [_vm._v(_vm._s(row.scope.companyName))]),
                      ]
                    },
                  },
                  {
                    key: "errorMessage",
                    fn: function (row) {
                      return [
                        row.scope.errorMessage
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  tabindex: 2000,
                                  content: row.scope.errorMessage,
                                  placement: "bottom",
                                },
                              },
                              [_c("i", { staticClass: "el-icon-warning red" })]
                            )
                          : _c("i", { staticClass: "el-icon-success green" }),
                      ]
                    },
                  },
                  {
                    key: "sendType",
                    fn: function (row) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(row.scope.express) +
                            " " +
                            _vm._s(row.scope.service) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "address",
                    fn: function (row) {
                      return _vm._l(row.scope.address, function (item, $index) {
                        return _c(
                          "p",
                          { key: $index, staticClass: "item-detail" },
                          [
                            _c("label", [_vm._v(_vm._s(item.label))]),
                            _c("span", [_vm._v(_vm._s(item.value))]),
                          ]
                        )
                      })
                    },
                  },
                  {
                    key: "surcharge",
                    fn: function (row) {
                      return _vm._l(
                        row.scope.surcharge,
                        function (item, $index) {
                          return _c(
                            "p",
                            { key: $index, staticClass: "item-detail" },
                            [
                              _c("label", [_vm._v(_vm._s(item.label))]),
                              _c("span", [_vm._v(_vm._s(item.value))]),
                            ]
                          )
                        }
                      )
                    },
                  },
                  {
                    key: "surchargeRMB",
                    fn: function (row) {
                      return _vm._l(
                        row.scope.surchargeRMB,
                        function (item, $index) {
                          return _c(
                            "p",
                            { key: $index, staticClass: "item-detail" },
                            [
                              _c("label", [_vm._v(_vm._s(item.label))]),
                              _c("span", [_vm._v(_vm._s(item.value))]),
                            ]
                          )
                        }
                      )
                    },
                  },
                  {
                    key: "weight",
                    fn: function (row) {
                      return _vm._l(row.scope.weight, function (item, $index) {
                        return _c(
                          "p",
                          { key: $index, staticClass: "item-detail" },
                          [
                            _c("label", [_vm._v(_vm._s(item.label))]),
                            _c("span", [_vm._v(_vm._s(item.value))]),
                          ]
                        )
                      })
                    },
                  },
                  {
                    key: "rate",
                    fn: function (row) {
                      return _vm._l(row.scope.rate, function (item, $index) {
                        return _c(
                          "p",
                          { key: $index, staticClass: "item-detail" },
                          [
                            _c("label", [_vm._v(_vm._s(item.label))]),
                            _c("span", [_vm._v(_vm._s(item.value))]),
                          ]
                        )
                      })
                    },
                  },
                  {
                    key: "costAmount",
                    fn: function (row) {
                      return [_vm._v(" " + _vm._s(row.scope.costAmount) + " ")]
                    },
                  },
                  {
                    key: "opt",
                    fn: function (row) {
                      return [
                        _c(
                          "el-popover",
                          { attrs: { placement: "left", trigger: "hover" } },
                          [
                            _c(
                              "ul",
                              _vm._l(row.scope.surchargeItems, function (a, i) {
                                return _c(
                                  "li",
                                  { key: i, staticClass: "detail-item" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-left": "16px" },
                                      },
                                      [_vm._v(_vm._s(a.label) + "：")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "detail-money",
                                        staticStyle: { "margin-right": "16px" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(a.value) +
                                            " " +
                                            _vm._s(a.currency)
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "tips",
                                staticStyle: { color: "#2395f3" },
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [_vm._v(" 费用明细 ")]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                2147280678
              ),
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }