<template>
  <div class="view-contanier">
    <div class="opt">
      <el-input
        v-model="search"
        style="width: 500px"
        placeholder="请输入订单编号，客户单号"
      ></el-input>
      <el-button
        style="margin-left: 10px; height: 40px;"
        type="primary"
        icon="el-icon-search"
        @click="searchRefNo"
      >查询</el-button
      >
    </div>
    <div class="data">
      <BaseTable
        v-if="height !== 0"
        :height="height"
        :isPager="false"
        :tableData="tableData"
        :loading="loading"
      >
        <template slot="companyName" slot-scope="row">
          <div
            v-if="row.tIndex === 0 && !row.scope.errorMessage"
            class="optimal flex_c_c"
          >
            <img src="@/assets/images/order/praise.png" />价格最优
          </div>
          <div>{{ row.scope.companyName }}</div>
        </template>
        <template slot="errorMessage" slot-scope="row">
          <el-tooltip
            v-if="row.scope.errorMessage"
            class="item"
            effect="dark"
            :tabindex="2000"
            :content="row.scope.errorMessage"
            placement="bottom"
          >
            <i class="el-icon-warning red" />
          </el-tooltip>
          <i v-else class="el-icon-success green" />
        </template>
        <template slot="sendType" slot-scope="row">
          {{ row.scope.express }} {{ row.scope.service }}
        </template>
        <template slot="address" slot-scope="row">
          <p
            v-for="(item, $index) in row.scope.address"
            :key="$index"
            class="item-detail"
          >
            <label>{{ item.label }}</label>
            <span>{{ item.value }}</span>
          </p>
        </template>
        <template slot="surcharge" slot-scope="row">
          <p
            v-for="(item, $index) in row.scope.surcharge"
            :key="$index"
            class="item-detail"
          >
            <label>{{ item.label }}</label>
            <span>{{ item.value }}</span>
          </p>
        </template>
        <template slot="surchargeRMB" slot-scope="row">
          <p
            v-for="(item, $index) in row.scope.surchargeRMB"
            :key="$index"
            class="item-detail"
          >
            <label>{{ item.label }}</label>
            <span>{{ item.value }}</span>
          </p>
        </template>
        <template slot="weight" slot-scope="row">
          <p
            v-for="(item, $index) in row.scope.weight"
            :key="$index"
            class="item-detail"
          >
            <label>{{ item.label }}</label>
            <span>{{ item.value }}</span>
          </p>
        </template>
        <template slot="rate" slot-scope="row">
          <p
            v-for="(item, $index) in row.scope.rate"
            :key="$index"
            class="item-detail"
          >
            <label>{{ item.label }}</label>
            <span>{{ item.value }}</span>
          </p>
        </template>
        <template slot="costAmount" slot-scope="row">
          {{ row.scope.costAmount }}
        </template>
        <template slot="opt" slot-scope="row">
          <el-popover placement="left" trigger="hover">
            <ul>
              <li
                v-for="(a, i) in row.scope.surchargeItems"
                :key="i"
                class="detail-item"
              >
                <span style="margin-left: 16px">{{ a.label }}：</span>
                <span
                  style="margin-right: 16px"
                  class="detail-money"
                >{{ a.value }} {{ a.currency }}</span
                >
              </li>
            </ul>
            <span slot="reference" style="color: #2395f3" class="tips">
              费用明细
            </span>
          </el-popover>
        </template>
      </BaseTable>
    </div>
  </div>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
import { quotesResult } from '@/api/client'
import { mapGetters } from 'vuex'
import { treeToList } from '@/utils/common'
import { myMixins } from '@/mixins'
export default {
  name: 'FeeSearch',
  components: { BaseTable },
  mixins: [myMixins],
  data() {
    return {
      loading: false,
      tableData: {
        fileds: [
          {
            filed: 'companyName',
            label: '客户',
            width: '130px',
            fixed: 'left',
            isCustom: true
          },
          {
            filed: 'referenceNumber',
            label: '客户单号',
            width: '140px',
            fixed: 'left',
            isCopy: true,
            align: 'left'
          },
          {
            filed: 'errorMessage',
            label: '错误信息',
            width: '100px',
            isCustom: true,
            fixed: 'left'
          },
          {
            filed: 'sendType',
            label: '派送方式',
            width: '120px',
            isCustom: true,
            fixed: 'left'
          },
          {
            filed: 'qty',
            label: '件数',
            width: '100px'
          },
          {
            filed: 'accountNumber',
            label: '制单账号',
            width: '140px'
          },
          {
            filed: 'originCityId',
            label: '始发地',
            width: '140px',
            formatter: row => {
              const addressList = treeToList(this.addressDropOption, 'children')
              const country = addressList.find(a => a.value === row.originCityId)
              return country?.label
            }
          },
          {
            filed: 'address',
            label: '地址',
            width: '230px',
            isCustom: true
          },
          {
            filed: 'surcharge',
            label: '费用(本位币)',
            width: '180px',
            isCustom: true
          },
          {
            filed: 'surchargeRMB',
            label: '费用(RMB)',
            width: '180px',
            isCustom: true
          },
          {
            filed: 'weight',
            label: '重量',
            width: '160px',
            isCustom: true
          },
          {
            filed: 'rate',
            label: '费率',
            width: '160px',
            isCustom: true
          },
          {
            filed: 'costAmount',
            label: '成本(含燃油、账号使用费)',
            width: '180px',
            isCustom: true
          },
          {
            filed: 'opt',
            label: '操作',
            width: '180px',
            isCustom: true,
            fixed: 'right'
          }
        ],
        columns: [],
        total: 0
      },
      height: 0,
      dialogVisible: false,
      editData: {},
      activeOption: [
        {
          value: true,
          label: '使用中'
        },
        {
          value: false,
          label: '未使用'
        }
      ],
      isActive: false,
      supplierScopeOptions: [],
      search: ''
    }
  },
  computed: {
    ...mapGetters(['addressDropOption'])
  },
  watch: {
    '$store.state.connect.connect': {
      handler: function(query) {
        if (query.queryJson) {
          const queryJson = JSON.parse(query.queryJson)
          this.search = queryJson.search
          this.getList()
          console.log(query)
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {},
  mounted() {
    if (!this.addressDropOption || this.addressDropOption.length === 0) {
      this.$store.dispatch('getAddress')
    }
    this.height = document.querySelector('.data').offsetHeight - 43
  },
  beforeDestroy() {
    this.$store.commit('SET_CONNNET', {})
  },
  methods: {
    searchRefNo() {
      this.getList()
    },
    getList(params) {
      if (!this.search) {
        return
      }
      this.loading = true
      try {
        params = {
          search: this.search
        }
        quotesResult(params).then((res) => {
          this.loading = false
          if (res.success) {
            this.tableData.total = res.data.total
            this.tableData.columns = res.data
              .sort((a, b) => a.surchargeRMB[0].value - b.surchargeRMB[0].value)
              .sort((a, b) => !!a.errorMessage - !!b.errorMessage)
          } else {
            this.$message.error(res.errorMessage)
          }
        })
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.view-contanier {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  &:after {
    clear: both;
  }
  .opt {
    width: 100%;
    height: 40px;
    padding: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn {
    width: 80px;
    height: 30px;
    background: #2395f3;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
    margin-right: 10px;
    cursor: pointer;
  }
  .red {
    color: #fe453d;
  }
  .green {
    color: #33c481;
  }
  .data {
    width: 100%;
    height: calc(100% - 120px);
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
  }
  .optimal {
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    height: 30px;
    background: #333333;
    border-radius: 0px 0px 15px 0px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #feaa4f;
    img {
      width: 14px;
      height: 14px;
      margin-right: 3px;
    }
  }
  .circle {
    width: 6px;
    height: 6px;
    opacity: 1;
    display: inline-block;
    border-radius: 50%;
    margin-right: 6px;
  }
  .table-opt:deep(.el-button) {
    padding: 0;
  }
  .filter {
    width: 438px;
  }
}
.select {
  width: 100%;
  &:deep(.el-select) {
    width: 100%;
  }
}
.table-opt:deep(.el-button) {
  padding: 0;
}
.status {
  width: 28px;
  height: 19px;
  font-size: 14px;
  font-weight: 400;
  color: #2395f3;
  line-height: 20px;
  margin-left: 5px;
  margin-right: 31px;
}
.icon {
  font-size: 24px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.item-detail {
  width: 100%;
  height: auto;
  text-align: left;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  & + .item-detail {
    margin-top: 5px;
  }
}
.item-detail label {
  width: 80px;
  display: inline-block;
  background: #f7f7f7;
  text-align: center;
}
.item-detail span {
  margin-left: 5px;
  font-weight: bold;
}
</style>
